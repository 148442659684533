.accounts-input {
  margin-top: 5px;
}

.accounts-input p {
  color: crimson;
  margin: 0px;
  margin-top: 3px;
  font-size: 12px;
  font-weight: bold;
}

.common_width {
  width: 13em !important;
}
